.header {
    display: flex;
    justify-content: center;
    z-index: 1000;
}

.header_content {
    padding: 12px 32px;
    display: flex;
    width: 100%;
    justify-content: space-between;
    max-width: 1100px;

    @media (max-width: 600px) {
        padding: 12px;
    }
}

.sticky {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #9BC38D;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1);
}

.nav {
    display: flex;
    gap: 32px;
    align-items: center;

    @media (max-width: 600px) {
        gap: 18px;
    }

    @media (max-width: 400px) {
        gap: 12px;
    }
}

.link {
    font-size: 1.2rem;
    font-weight: 600;
    transition: all 0.1s ease;

    @media (max-width: 600px) {
        font-size: 1rem;
    }

    @media (max-width: 400px) {
        font-size: 0.8rem;
    }

    &:hover {
        filter: brightness(1.1);
        transform: scale(1.05);
    }

    &:active {
        filter: brightness(0.9);
    }
}

.logo_big {
    height: 60px;
    display: block;

    @media (max-width: 768px) {
        display: none;
    }
}

.logo_small {
    height: 40px;
    display: none;
    transform: scaleX(-1);

    @media (max-width: 768px) {
        display: block;
    }

    @media (max-width: 400px) {
        height: 32px;
    }
}
