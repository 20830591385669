.header-section {
    font-size: 4.5rem;
    color: #599d3c;
    font-weight: 800;
    -webkit-text-stroke-width: 1.2px;
    stroke-width: 1.2px;
    -webkit-text-stroke-color: #000;
    stroke: #000;
    margin-bottom: 24px;

    @media (max-width: 500px) {
        font-size: 3rem;
    }

    @media (max-width: 300px) {
        font-size: 2rem;
    }
}

.typography-body {
    font-size: 1.6rem;
    font-weight: 600;

    @media (max-width: 900px) {
        font-size: 1.3rem;
    }

    @media (max-width: 400px) {
        font-size: 1rem;
    }
}

.typography-description {
    font-size: 1rem;
    font-weight: 600;

    @media (max-width: 900px) {
        font-size: 0.9rem;
    }

    @media (max-width: 400px) {
        font-size: 0.7rem;
    }
}

.section {
    
}

.content {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
    max-width: 1100px;
    padding: 48px 24px;

    @media (max-width: 900px) {
        justify-content: center;
        padding: 24px 12px;
        text-align: center;
    }

    @media (max-width: 900px) {
        gap: 12px;
    }

    @media (max-width: 500px) {
        gap: 6px;
    }
}

.button-primary {
    padding: 12px 24px;
    font-size: 1rem;
    font-weight: 600;
    border-radius: 6px;
    background-color: #599d3c;
    color: white;
    border: 2px solid black;
    cursor: pointer;
    transition: all 0.3s ease;

    &:hover {
        background-color: #599d3c;
        color: white;
        filter: brightness(1.1);
        transform: scale(1.025);
    }

    &:active {
        filter: brightness(0.9);
    }
}

.main {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 48px;
    width: 100%;

    @media (max-width: 900px) {
        gap: 24px;
    }

    @media (max-width: 500px) {
        gap: 12px;
    }
}

.disabled {
    pointer-events: none;
    cursor: default;
    opacity: 0.5;
}
