.wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    gap: 40px;

    @media (max-width: 900px) {
        flex-direction: column-reverse;
    }
}

.name {
    font-size: 8rem;
    color: #599d3c;
    font-weight: 800;
    -webkit-text-stroke-width: 2px;
    stroke-width: 2px;
    -webkit-text-stroke-color: #000;
    stroke: #000;

    @media (max-width: 400px) {
        font-size: 4rem;
    }
}

.left {
    display: flex;
    flex-direction: column;
    gap: 18px;

    @media (max-width: 900px) {
        justify-content: center;
        text-align: center;
    }
}

.right {
    width: 100%;
    justify-content: center;
    display: flex;
}

.links {
    display: flex;
    gap: 18px;
    align-items: center;
    justify-content: flex-start;

    @media (max-width: 900px) {
        justify-content: center;
        flex-wrap: wrap;
    }
}

.shreke_head {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 400px;
    transform: scaleX(-1);

    @media (max-width: 900px) {
        max-height: 200px;
    }
}

.contract {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;
    min-width: 0;
}

.address {
    color: #599d3c;
    text-decoration: underline;
    white-space: pre-wrap;
    width: 100%;
    word-break: break-word;
    word-wrap: break-word;

    &:hover {
        filter: brightness(0.9);
    }
}

.buttons {
    display: flex;
    gap: 12px;
    align-items: center;
    width: 100%;
    button,
    a {
        max-width: 100%;
        width: 100%;
    }

    @media (max-width: 400px) {
        flex-wrap: wrap;
    }
}
