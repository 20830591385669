.wrapper {
    display: flex;
    gap: 24px;
    align-items: center;
    flex-direction: column;
}

.content {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: row;
    gap: 40px;
    width: 100%;

    @media (max-width: 900px) {
        flex-direction: column;
        text-align: center;
    }
}

.phaseTitle {
    font-size: 2rem;
    font-weight: 700;
}

.phase {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
}
