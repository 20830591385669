.linkLogo {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;

    &:hover {
        transform: scale(1.05);
    }
}
