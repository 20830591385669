.wrapper {
    display: flex;
    gap: 24px;
    align-items: center;
    flex-direction: column;
}

.content {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 40px;
    justify-content: space-between;
    width: 100%;

    @media (max-width: 900px) {
        flex-direction: column;
        text-align: center;
    } 
}

.right {
    display: flex;
    justify-content: center;
    height: 100%;
    max-height: 260px;
}

.left {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
    align-items: center;
}

.shrekeFull {
    height: 400px;
    transform: scaleX(-1);
}

.ulWrapper {
    display: flex;
    flex-direction: column;
    gap: 24px;
}
