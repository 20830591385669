.wrapper {
    display: flex;
    gap: 24px;
    align-items: center;
    flex-direction: column;
}

.content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    gap: 40px;

    @media (max-width: 900px) {
        flex-direction: column;
        text-align: center;
    } 
}

.left {
    flex: 1;
    display: flex;
    justify-content: center;
}

.right {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.shrekeFull {
    height: 400px;
    transform: scaleX(-1);
}
