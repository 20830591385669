.footer {
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin: 0 auto;
    margin-top: 64px;
}

.textWrapper {
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.links {
    display: flex;
    gap: 18px;
    align-items: center;
    justify-content: center;
    @media (max-width: 900px) {
        flex-wrap: wrap;
    }
}

.copyright {
    font-size: 1.2rem;
    font-weight: 600;
}
